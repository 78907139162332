@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.animated-background, .card-loader:empty {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  border-radius: 0.25rem; }
