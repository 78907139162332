body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans';
  font-size: 13px;
  color: #3d3d3d;
  padding-right: 0px !important; }

.primary-background {
  background-color: #ED7818;
  color: #ffffff; }

.primary-color {
  color: #ED7818;
  background-color: #ffffff; }

header.navbar .navbar-nav .nav-item {
  border-left: 1px solid #efa76c;
  color: #ffe7d4; }

header.navbar .dropdown.show .user-ico {
  color: #ffffff; }

header.navbar .dropdown .user-ico {
  color: #ffe7d4; }

header.navbar .navbar-nav .nav-item .dropdown-item.active, header.navbar .navbar-nav .nav-item .dropdown-item:active {
  color: #ffffff;
  background-color: #ED7818; }

.side-menu-item > .list-group-item > a.opened,
.side-menu-item > .list-group-item > a.selected,
.side-menu-item > .list-group-item > a.opened .menu-icon,
.side-menu-item > li > a:hover {
  color: #ED7818; }

.side-menu-item > .list-group-item > a.opened, .side-menu-item > .list-group-item > a.selected {
  color: #ED7818;
  font-weight: bold; }

.side-menu-item > li > a:hover .menu-icon {
  color: #ED7818; }

.side-submenu-item > li > a:hover, .side-submenu-item > .list-group-item > a.selected {
  border-right: 2px solid #ED7818;
  color: #ED7818; }

.side-submenu-item > li > a:not([href]):not([tabindex]):focus, .side-submenu-item > li > a:not([href]):not([tabindex]):hover {
  color: #ED7818; }

.fit-content {
  color: #ED7818; }

.data-table-rowCount-dd.dropdown-menu .dropdown-item.active,
.data-table-rowCount-dd.dropdown-menu .dropdown-item:active,
.dataTable_export_dd.dropdown-menu .dropdown-item.active,
.dataTable_export_dd.dropdown-menu .dropdown-item:active {
  color: #ffffff;
  background-color: #ED7818; }

.dataTable_selectedRow {
  background-color: #fcebdc !important; }

.selectedUser {
  background-color: #ED7818;
  color: #ffffff;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s; }

.custom-btn.custom-btn-primary {
  color: #ffffff;
  background-color: #ED7818;
  border: 1px solid #ED7818; }

.custom-btn.custom-btn-primary:hover {
  background-color: #ec8028; }

.custom-btn.custom-btn-secondary {
  color: #ED7818;
  background-color: #ffffff;
  border: 1px solid #ED7818; }

.custom-btn.btn-alert-primary {
  color: #ffffff;
  background-color: #ED765C;
  border: 1px solid #ED765C; }

.custom-btn.btn-alert-secondary {
  color: #0185E1;
  background-color: #ffffff;
  border: 1px solid #EDEDED; }

.color-primary {
  color: #ED7818 !important; }

.color-secondary {
  color: #6D6D6D !important; }

.custom-modal .modal-header .close {
  color: #ffffff; }

.custom-modal .primary-background {
  /* background-color: #ED7818; */
  background-color: #00aeef;
  color: #ffffff; }

.center-alert_box .modal-content {
  border: 1px solid #FF4A22; }

.center-alert_box .close {
  color: #ffffff; }

.center-alert_box .modal-header {
  background-color: #ED765C;
  border-radius: 0px;
  color: #ffffff; }

.center-alert_box .alert_ico {
  color: #ED765C; }

.pagination-SelectorModal > .active > a,
.pagination-SelectorModal > .active > span {
  color: #ffffff;
  background-color: #ED7818;
  border-color: #ED7818; }

.form-tab .nav-link.active {
  background: #ED7818;
  color: #ffffff; }

.form-tab .nav-link.done {
  background: #f19e55;
  color: #ffffff; }

.form-tab .nav-tabs {
  border-bottom: 1px solid #ED7818; }

.wizardTab .nav-link::after {
  border-color: transparent transparent transparent #ffffff; }

.wizardTab .active.nav-link::after {
  border-color: transparent transparent transparent #ED7818; }

.wizardTab .nav-tabs > li:not(:first-child) > .active.nav-link::before {
  border-color: #ED7818 #ED7818 #ED7818 transparent; }

.wizardTab .done.nav-link::after {
  border-color: transparent transparent transparent #f19e55; }

.wizardTab .nav-tabs > li:not(:first-child) > .done.nav-link::before {
  border-color: #f19e55 #f19e55 #f19e55 transparent; }

.wizardTab .nav-tabs > li:not(:first-child) > .nav-link::before {
  border-color: #ffffff #ffffff #ffffff transparent; }

.svd_container .select2-container .select2-selection--single .select2-selection__arrow,
.svd_container .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: #ED7818 !important; }

.svd_container a {
  color: #ED7818 !important; }

.svd_container a:hover {
  color: #ED7818 !important; }

.svd_container .btn-primary {
  background-color: #ED7818 !important;
  border-color: #ED7818 !important; }

.svd_container .sjs-cb-container:hover input:checked ~ .checkmark {
  background-color: #ED7818 !important; }

.svd_container .svd_toolbar li.active a {
  background-color: #ED7818 !important; }

.svd_container .nav-tabs > li.active > a {
  color: #ED7818 !important; }

.svd_container .editor-tabs > li.active > a {
  color: #ED7818 !important; }

.svd_container .svd_custom_select:before {
  background-color: #ED7818 !important; }

.svd_container .form-control:focus {
  border-color: #0185E1 !important; }

.svd_container .svd-main-color {
  color: #ED7818 !important; }

.svd_container .svd-main-background-color {
  background-color: #ED7818 !important; }

.svd_container .svd-main-border-color {
  border-color: #ED7818 !important; }

.svd_container .svd-primary-icon .svd-svg-icon {
  fill: #ED7818 !important; }

.svd_container .svd-secondary-icon .svd-svg-icon {
  fill: #ED7818 !important; }

.svd_container .icon-gearactive .svd-svg-icon {
  fill: #ED7818 !important; }

.sv_main input[type="button"],
.sv_main button {
  color: #ffffff !important;
  background-color: #ED7818 !important; }

.sv_main input[type="button"]:hover,
.sv_main button:hover {
  background-color: #ED7818 !important; }

.sv_main .sv_select_wrapper {
  background-color: #ffffff !important; }

.sv_main .sv_select_wrapper:before {
  background-color: #ED7818 !important; }

.sv_main .sv_body {
  background-color: #ffffff !important;
  border-top: 2px solid #ED7818 !important; }

.sv_main .sv_progress_bar {
  background-color: #ED7818 !important; }

.sv_main .sv_q_m_cell_selected {
  color: #ffffff !important;
  background-color: #ED7818 !important; }

.sv_main .sv_q_rating_item.active .sv_q_rating_item_text {
  background-color: #ED7818 !important;
  border-color: #ED7818 !important;
  color: #ffffff !important; }

.sv_main .sv_q_rating_item .sv_q_rating_item_text:hover {
  border: 1px solid #ED7818 !important; }

.tree-node.selected {
  color: #ED7818; }

.tree-node.selected .node-label {
  border-color: #ED7818; }

.tree-view-btn-grp > .fa {
  color: #ED7818; }

.tree-view li::before {
  border-left: 1px solid #ED7818; }

.tree-view li:first-child::after {
  border-top: 2px solid #ED7818; }

.tree-view .node-bullet {
  background: #ED7818; }

.org-node {
  border: 1px solid #D6D6D6;
  background: #F5F5F6; }

.org-node-type-1 {
  background: #A3B4BC;
  border-color: #A3B4BC; }

.org-node-type-2 {
  background: #409ccd;
  border-color: #409ccd; }

.org-node-selected {
  border-color: #ED7818;
  background: #ED7818; }

.legend-bullet > span {
  background: #F5F5F6; }

.legend-bullet-1 > span {
  background: #A3B4BC; }

.legend-bullet-2 > span {
  background: #409ccd; }

.menu-icon-o:hover,
.dropdown.show > .menu-icon-o {
  background-color: #ED7818;
  border: 1px solid #ED7818; }

.customico-menu-o {
  font-size: 16px; }

.customico-menu-o .fa-ellipsis-h {
  font-size: 1.4em; }

/* **********ListData css************** */
.list-data:hover {
  background-color: #ffe7d4; }

ul.list-background > li:nth-of-type(odd) {
  background: #f5f5f5; }

ul.list-background > li:nth-of-type(odd):hover {
  background-color: #ffe7d4; }

.primary-color-list {
  color: #ED7818; }

/* **********Drag and Drop ************** */
.list-submenu-item > li > a {
  background: #ffe7d4;
  border-bottom: 1px solid #ccc; }

.draggable-item:hover {
  background: #ffe7d4; }

.list-menu-item > .list-group-item > a.opened, .list-menu-item > .list-group-item > a.selected, .list-menu-item > li > a:hover {
  color: #ffffff;
  background: #ED7818; }

.draggable-item {
  border: 1px solid #ccc;
  padding-top: 3px;
  padding-left: 10px;
  padding-bottom: 3px;
  cursor: move;
  background-color: #fff5eb;
  display: flex; }
  .react-datepicker__close-icon{
    display: none;
  }
